import React from 'react';
import './list.sass';

interface Props {
  data: Array<{ title: string, description: string }>;
}

export function List({ data }: Props) {
  return (
    <div className="columns is-multiline">
      {data.map((el, idx) => (
        <div className="column is-one-third" key={idx}>
          <div className="list-item">
            <h3>{el.title}</h3>
            <div>{el.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
