import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import './layout.sass';
import Header from './header';
import Footer from './footer';

const Layout = ({ children, pageType, pathname}) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              instagramUrl
              linkedInUrl
              twitterUrl
              siteUrl
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            <Header
              siteTitle={data.site.siteMetadata.title}
              pageType={pageType || 'homepage'}
              pathname={pathname}
            />
            <div className="page-wrapper">{children}</div>
            <Footer {...data.site.siteMetadata} />
          </>
        );
      }}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  pageType: PropTypes.oneOf(['homepage', 'blog']),
  pathname: PropTypes.string,
};

Layout.defaultProps = {
  pageType: 'homepage',
};

export default Layout;
