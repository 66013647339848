import { Link as GLink } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import UiImg from './UiImg';
import './header.sass';
import cx from 'classnames';
import { scroller, Link as RsLink } from 'react-scroll';
import { observer } from 'mobx-react';
import { observable } from 'mobx';

@observer
export default class Header extends React.Component {
  static props = {
    siteTitle: PropTypes.string,
    pageType: PropTypes.string.isRequired,
    pathname: PropTypes.string,
  };

  static defaultProps = {
    siteTitle: '',
  };

  @observable
  activeSection: string = 'home';
  @observable
  isOpen: boolean = false;
  @observable
  isScrollBelowTop: boolean = false;
  @observable
  isScrollDown: boolean = false;

  setActiveSection = section => {
    this.activeSection = section;
  };

  componentDidMount(): void {
    let $win = $(window);
    // Scroll up and down
    let c,
      currentScrollTop = 0;
    let navbar = $('nav');

    const handleScroll = () => {
      let a = $win.scrollTop();
      let b = navbar.height();

      currentScrollTop = a;

      this.isScrollBelowTop = a > 70;

      if (c < currentScrollTop && a > 50) {
        this.isOpen = false;
        this.isScrollDown = true;
      } else if (c > currentScrollTop && !(a <= b)) {
        this.isScrollDown = false;
      }
      c = currentScrollTop;
    };

    $win.scroll(handleScroll);

    handleScroll();
  }

  render() {
    const activeSection = this.activeSection;
    const { pageType } = this.props;
    const linkType = pageType === 'homepage' ? 'anchor' : 'link';

    const pathname = this.props.pathname || typeof window !== 'undefined' && location.pathname || '';

    return (
      <nav
        className={cx(
          'navbar is-fixed-top is-sticky',
          { 'is-open': this.isOpen },
          { 'scroll-below-top': this.isScrollBelowTop },
          { 'scroll-down': this.isScrollDown },
          { 'is-dark': pageType === 'blog' }
        )}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container">
          <div className="navbar-brand">
            <Link
              className="navbar-item"
              href="/"
              to="home"
              smooth={true}
              duration={500}
              linkType={linkType}
            >
              {pageType === 'homepage' ? (
                <UiImg
                  src="/images/logo-g2.svg"
                  alt="Node Vision"
                  className="logo"
                  title="Node Vision"
                />
              ) : (
                <UiImg
                  src="/images/logo-funky-2.svg"
                  alt="Node Vision"
                  className="logo"
                  title="Node Vision"
                />
              )}
            </Link>
            <a
              role="button"
              className={cx('navbar-burger', { 'is-active': this.isOpen })}
              aria-label="menu"
              aria-expanded="false"
              onClick={() => {
                this.isOpen = !this.isOpen;
              }}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>

          <div
            className={cx('navbar-menu', {
              'is-active': this.isOpen,
            })}
          >
            <div className="navbar-end">
              <Link
                href="/"
                to="home"
                className={cx('navbar-item', 'is-hidden', {
                  'is-active': activeSection === 'home',
                })}
                onSetActive={this.setActiveSection}
                spy={true}
                smooth={true}
                duration={500}
                onClick={() => {
                  this.isOpen = false;
                }}
                linkType={linkType}
              >
                <span>Home</span>
              </Link>
              <Link
                href="#our-work"
                to="our-work"
                className={cx('navbar-item', {
                  'is-active': activeSection === 'our-work',
                })}
                onSetActive={this.setActiveSection}
                spy={true}
                smooth={true}
                duration={500}
                onClick={() => {
                  this.isOpen = false;
                }}
                linkType={linkType}
              >
                <span>Our Work</span>
              </Link>
              <Link
                href="#services"
                to="services"
                className={cx('navbar-item', {
                  'is-active': activeSection === 'services',
                })}
                onSetActive={this.setActiveSection}
                spy={true}
                smooth={true}
                duration={500}
                onClick={() => {
                  this.isOpen = false;
                }}
                linkType={linkType}
              >
                <span>What we Do</span>
              </Link>
              <Link
                href="#about-us"
                to="about-us"
                className={cx('navbar-item', {
                  'is-active': activeSection === 'about-us',
                })}
                onSetActive={this.setActiveSection}
                spy={true}
                smooth={true}
                duration={500}
                onClick={() => {
                  this.isOpen = false;
                }}
                linkType={linkType}
              >
                <span>About us</span>
              </Link>
              <GLink
                to="/contact"
                className={cx('navbar-item', {
                  'is-active': pathname?.indexOf('/contact') > -1,
                })}
              >
                <span>Contact</span>
              </GLink>
              <a href="javascript:void(0)" className={cx('navbar-item')}>
                <span>Products</span>
                <div className="sub-menu">
                  <a href="/employee-directory" className={cx('navbar-item')}>
                    <span>Employee Directory</span>
                  </a>
                  <a href="/image-gallery" className={cx('navbar-item')}>
                    <span>Image Gallery</span>
                  </a>
                  <a href="/user-poll" className={cx('navbar-item')}>
                    <span>User Poll</span>
                  </a>
                  <a
                    href="/read-speaker-for-sharepoint"
                    className={cx('navbar-item')}
                  >
                    <span>ReadSpeaker for SharePoint</span>
                  </a>
                </div>
              </a>
              <GLink
                to="/blog/"
                className={cx('navbar-item', {
                  'is-active': pathname?.indexOf('/blog') > -1,
                })}
              >
                <span>Blog</span>
              </GLink>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

const Link = ({ children, linkType, ...rest }) => {
  if (linkType === 'anchor') return <RsLink {...rest}>{children}</RsLink>;
  else
    return (
      <GLink to={`/${rest.href}`} className={rest.className}>
        {children}
      </GLink>
    );
};
