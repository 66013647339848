import React from 'react';
import Layout from '../components/layout';
import './index.sass';
import UiImg from '../components/UiImg';
import { Link, scroller } from 'react-scroll';
import { graphql } from 'gatsby';
import sortBy from 'lodash/sortBy';
import SEO from '../components/seo';
import '../styling/animate.css';
import { List } from '../components/list';
import SignUpSection from '../components/signup';

export default class IndexPage extends React.Component {
  componentDidMount() {
    if (typeof window !== 'undefined') {
      let words = $('.animate-rotate');
      let idx = 0;
      let animateWords = words => {
        let $current = $(words[idx]);
        let $next = $(words[idx + 1 === words.length ? 0 : idx + 1]);
        $current.removeClass('bounceInRight');
        $current.addClass('bounceOutLeft');
        setTimeout(() => {
          $current.removeClass('bounceInRight bounceOutLeft');
          $next.removeClass('bounceInRight bounceOutLeft');
          $next.addClass('bounceInRight');
          idx = idx === words.length - 1 ? 0 : idx + 1;
        }, 550);
      };

      animateWords(words);
      setInterval(() => {
        animateWords(words);
      }, 3500);

      $('.lazy').Lazy({
        afterLoad: function(element) {
          element.addClass('loaded');
          element.parent().addClass('child-img-loaded');
        },
        effect: 'fadeIn',
      });
    }
  }

  render() {
    const data = this.props.data;
    let portfolio: Array<{
      id: string,
      title: string,
      description: string,
      image: string,
      tech: string,
      link: string,
    }> = data.portfolio.edges.map(el => {
      return el.node.childPortfolioJson;
    });
    let brands: Array<{
      id: string,
      title: string,
      image: string,
      link: string,
    }> = data.brands.edges.map(el => {
      return el.node.childBrandsJson;
    });
    let services: Array<{
      id: string,
      title: string,
      description: string,
      order: number,
    }> = data.services.edges.map(el => {
      return el.node.childServicesJson;
    });
    return (
      <Layout>
        <SEO />
        <div className="page-home">
          <section className="landing" id="home">
            <video id="myVideo" autoPlay playsInline muted loop>
              <source src="/images/bg-video.mp4" type="video/mp4" />
            </video>

            <div className="container">
              <h1>
                <div className="smaller">We help teams build</div>
                <div className="animate-rotate animated g-orange">
                  breakthrough
                </div>
                <div className="animate-rotate animated g-green">impactful</div>
                <div className="animate-rotate animated g-blue">scalable</div>
                <div className="smaller">digital solutions and ventures</div>
              </h1>
              <h2>
                <span className="node-vision">node vision</span> is an
                Australian digital product development consultancy with
                expertise in designing, building and operating breakthrough
                solutions using modern technologies.
              </h2>
              {/*<div className="techs">*/}
              {/*  Node JS <span className="bullet" /> React{' '}*/}
              {/*  <span className="bullet" /> MongoDB <span className="bullet" />{' '}*/}
              {/*  GraphQL <span className="bullet" /> SharePoint*/}
              {/*</div>*/}
              <div className="btn-section">
                <button
                  onClick={() => {
                    scroller.scrollTo('contact', {
                      smooth: true,
                      duration: 800,
                    });
                  }}
                >
                  Get in touch
                </button>
                <span>
                  or{' '}
                  <Link
                    href="#our-work"
                    to="our-work"
                    className="link"
                    smooth={true}
                    duration={400}
                  >
                    check out our work
                  </Link>
                </span>
              </div>
            </div>
            <div className="go-to-work">
              <UiImg alt="Go to Work section" src="/images/arrow-down.svg" />
            </div>
          </section>
          <section className="work" id="our-work">
            <div className="container">
              <h2>Our work speaks for itself</h2>
              <div className="section-subtitle">
                <span className="node-vision">node vision</span> helped multiple
                start-ups and enterprises across different industries to bring
                their ideas to life.
              </div>
              <div className="columns is-multiline portfolio">
                {sortBy(portfolio, ['order']).map(el => (
                  <PortfolioItem key={el.id} {...el} />
                ))}
                <div className="column is-one-third your-project">
                  <img
                    src="/images/1px.png"
                    alt="1px bg"
                    className="one-pixel"
                  />

                  <h3>Your project</h3>
                </div>
              </div>
              <div className="ask-us">
                <a
                  href="mailto:info@nodevision.com.au?subject=portfolio"
                  className="is-button with-border with-dark-bg"
                >
                  Ask for the full portfolio
                </a>
              </div>
            </div>
          </section>
          <section className="clients" id="clients">
            <div className="container">
              <h2>Our clients</h2>
              <div className="section-subtitle">
                Come to work with us and you will be in a good company.
              </div>
              <div className="columns is-multiline brands">
                {brands.map(el => (
                  <Brand key={el.id} {...el} />
                ))}
              </div>
            </div>
          </section>
          <section className="what-we-do" id="services">
            <div className="container">
              <h2>What we do</h2>
              <List data={sortBy(services, ['order'])} />
            </div>
          </section>
          <section className="who-we-are" id="about-us">
            <div className="container">
              <h2>About us</h2>
              <div className="about-node-vision">
                <span className="node-vision">node vision</span> is founded by
                product geeks, startup technical founders and venture builders
                combining decades of technical, product and commercial strategy
                experience in startup and corporate environments. Our mission is
                to build purposeful, real-world, and breakthrough solutions to
                the toughest challenges.
              </div>
              {/*<div className="photos">*/}
              {/*  <img*/}
              {/*    data-src="/images/photo-roman-1.jpg"*/}
              {/*    src="/images/placeholder-350x350.png"*/}
              {/*    className="lazy"*/}
              {/*    alt="Roman"*/}
              {/*    title="Roman"*/}
              {/*  />*/}
              {/*  <img*/}
              {/*    data-src="/images/photo-david-1.jpg"*/}
              {/*    src="/images/placeholder-350x350.png"*/}
              {/*    className="lazy"*/}
              {/*    alt="David"*/}
              {/*    title="David"*/}
              {/*  />*/}
              {/*</div>*/}
              <div className="columns is-multiline">
                <div className="column" />
                <div className="column" />
              </div>
            </div>
          </section>
          <SignUpSection bgColor="#ededed" />
        </div>
      </Layout>
    );
  }
}

const PortfolioItem = ({ image, title, link, tech, description }) => {
  return (
    <div className="column is-one-third">
      <div className="portfolio-item">
        <a href={link} target="_blank">
          <img
            src="/images/1px.png"
            alt="1px bg"
            className="one-pixel"
            title="1px bg"
          />
          <img
            data-src={image}
            alt={title}
            className="lazy hero"
            title={title}
          />
          <div className="dimmer" />
          <div className="info">
            <div className="title">{title}</div>
            <div className="desc">{description}</div>
            {tech && (
              <div className="tech">
                {tech.split(';').map((el, idx) => (
                  <span key={idx}>{el} </span>
                ))}
              </div>
            )}
          </div>
        </a>
      </div>
    </div>
  );
};

const Brand = ({ id, title, image, link, className }) => (
  <div className="column is-one-quarter">
    <img src={image} alt={title} className={`lazy`} title={title} />
  </div>
);

export const query = graphql`
  query HomePageQuery {
    brands: allFile(filter: { sourceInstanceName: { eq: "brands" } }) {
      edges {
        node {
          childBrandsJson {
            id
            title
            image
            link
          }
        }
      }
    }
    portfolio: allFile(filter: { sourceInstanceName: { eq: "portfolio" } }) {
      edges {
        node {
          childPortfolioJson {
            id
            title
            image
            tech
            description
            link
            order
          }
        }
      }
    }
    services: allFile(filter: { sourceInstanceName: { eq: "services" } }) {
      edges {
        node {
          childServicesJson {
            id
            title
            description
            order
          }
        }
      }
    }
  }
`;
