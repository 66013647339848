import React from 'react';
import './footer.sass';

const Footer = ({ instagramUrl, linkedInUrl, twitterUrl, ...rest }) => {
  return (
    <section className="footer">
      <div className="container">
        <div className="columns">
          <div className="column logo">
            <img src="/images/logo-funky-2.svg" alt="Logo" />
            <div className="sublogo">Sydney JavaScript Experts</div>
          </div>
          <div className="column">
            <div className="social">
              <a href={linkedInUrl} target="_blank">
                <img
                  src="/images/ico-linkedin.svg"
                  alt="Linkedin"
                  className="linkedin"
                  title="Linkedin"
                />
              </a>
              <a href={twitterUrl} target="_blank">
                <img src="/images/ico-twitter.svg" alt="Twitter" title="Twitter" />
              </a>
              <a href={instagramUrl} target="_blank">
                <img
                  src="/images/ico-instagram.svg"
                  alt="Instagram"
                  className="insta"
                  title="Instagram"
                />
              </a>
            </div>
            <div className="info">
              111/20 Dale St, Sydney NSW 2100 Australia
              <br />
              <a href="mailto:info@nodevision.com.au" className="link">
                info@nodevision.com.au
              </a>
              <br />
              <a href="tel://+61402694339" className="link">
                +61 402 694 339
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
