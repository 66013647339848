import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, meta, keywords, title, ogImage, siteUrl }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        const metaKeywords = keywords || data.site.siteMetadata.keywords;
        const metaTitle = title || data.site.siteMetadata.title;
        const metaOgImage = ogImage || data.site.siteMetadata.ogImage;
        const metaSiteUrl = siteUrl || data.site.siteMetadata.siteUrl;
        const metaGoogleSiteVerification = data.site.siteMetadata.googleSiteVerification;
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={metaTitle}
            meta={[
              {
                name: "google-site-verification",
                content: metaGoogleSiteVerification
              },
              {
                name: `description`,
                content: metaDescription,
              },
              {
                property: `og:title`,
                content: metaTitle,
              },
              {
                property: `og:description`,
                content: metaDescription,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: 'Node Vision',
              },
              {
                name: `twitter:title`,
                content: metaTitle,
              },
              {
                name: `twitter:description`,
                content: metaDescription,
              },
              {
                name: `keywords`,
                content: metaKeywords,
              },
              {
                name: `og:image`,
                content: metaSiteUrl + metaOgImage,
              }
            ].concat(meta)}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: []
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        keywords   
        ogImage    
        siteUrl
        googleSiteVerification
      }
    }
  }
`;
