import React, { Fragment } from 'react';
import './signup.sass';
import TextField from '@material-ui/core/es/TextField/TextField';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import * as yup from 'yup';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#333',
    },
    secondary: {
      main: '#009cf3',
    },
  },
  typography: {
    fontSize: 17,
    fontFamily: ['Lato', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"'],
  },
  overrides: {
    MuiInput: {
      root: {
        paddingBottom: '0.2rem',
        marginBottom: '0.3rem',
      },
      underline: {
        '&:hover:before': {
          borderBottomWidth: '1px !important',
        },
        '&:after': {
          borderBottom: '#009cf3 solid 1px',
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: '#000',
      },
      shrink: {
        transform: 'translate(0, -4px) scale(0.75)',
      },
      error: {
        color: '#333 !important',
      },
    },
    MuiIconButton: {
      root: {
        padding: '7px 12px',
      },
    },
  },
});

@observer
export default class SignUpSection extends React.Component<{
  bgColor?: string,
}> {
  @observable
  data = {
    email: '',
  };

  @observable
  messageSent = false;

  @observable
  isValid = {
    email: true,
  };

  @observable
  error = '';

  handleChange = name => event => {
    this.data[name] = event.target.value;
  };

  isFormValid = () => {
    return yup
      .string()
      .email()
      .required()
      .isValidSync(this.data.email);
  };

  submit = async e => {
    try {
      e.preventDefault();
      const { result, msg } = await addToMailchimp(this.data.email);
      if (result === 'error') {
        this.error = msg;
      }
    } catch (e) {
      this.error = e.toString();
    } finally {
      this.messageSent = true;
    }
  };

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  };

  render() {
    const { email } = this.data;
    let data = this.data;

    const bgColor = this.props.bgColor || '#ededed';

    return (
      <section
        className="sign-up-section"
        id="sign-up"
        style={{ backgroundColor: bgColor }}
      >
        <div className="container">
          <h2>Newsletter</h2>
          {this.messageSent && !this.error && (
            <div className="response-wrapper">
              <div className="ico">
                <img src="/images/ico-check.svg" align="Thank you" />
              </div>
              <div className="title">Thank you for signing up!</div>
            </div>
          )}
          {this.messageSent && this.error && (
            <div className="response-wrapper">
              <div className="error">
                Something went wrong.
                <br />
                <br />
                {this.error}
              </div>
            </div>
          )}

          {!this.messageSent && (
            <Fragment>
              <div className="sign-up-subtitle">
                News, updates and so much more! Sign up today!
              </div>

              <div className="form">
                <form name="signup" onSubmit={this.submit}>
                  <MuiThemeProvider theme={theme}>
                    <TextField
                      label="Your email"
                      fullWidth
                      margin={'normal'}
                      placeholder="name@company.com"
                      type="email"
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange={this.handleChange('email')}
                      onBlur={() => {
                        this.isValid.email = yup
                          .string()
                          .email()
                          .required()
                          .isValidSync(data.email);
                      }}
                      error={!this.isValid.email}
                      helperText={
                        !this.isValid.email &&
                        'Please enter a valid email address'
                      }
                    />
                  </MuiThemeProvider>
                  <div className="btn-wrapper">
                    <button type="submit" disabled={!this.isFormValid()}>
                      Sign up
                    </button>
                  </div>
                </form>
              </div>
            </Fragment>
          )}
        </div>
      </section>
    );
  }
}
